import { Card, Typography, Button, Box, CardActions } from "@mui/material";
import LiveBadge from "./LiveBadge.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import { useNavigate } from "react-router-dom";
import { useContext, memo, useMemo } from "react";
import EventProfileBg from "./EventProfileBg.js";
import { formatSubscribers, getEventUniqueNumber } from "src/utils/Utils.js";
import SubscribeButton from "../SubscribeButton.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { EventComposer } from "src/utils/EventComposer.js";
import { useDisplayImage } from "src/utils/useDisplayImage.js";
import DefaultImageBasketball from "./DefaultImageBasketball.js";
import { customEvent } from "src/utils/gtag.js";

const RecommendedCalendar = ({
  eventData = {},
  eventUI = {},
  calendar = {},
  calendarId,
  position,
  onSubscribeAction,
  index,
  showRanking,
}) => {
  // Extract values with defaults to handle null props
  const {
    eventId = "",
    eventSummary = [],
    formattedDescription = "",
    isEventLive = false,
  } = eventData || {};

  const {
    homeTeamLogo = null,
    background = null,
    eventImageUrl = null,
  } = eventUI || {};

  // Hooks
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const { isMediumScreen } = useDimensions();
  const navigate = useNavigate();

  const { displayImage, loading, handleImageError } = useDisplayImage({
    type: "RecommendedCalendar",
    calendar,
    homeTeamLogo,
    eventImageUrl,
    background,
  });

  // Navigation handler
  const handleNavigate = () => {
    if (calendar?.handle && calendar?._id) {
      customEvent({
        name: "recommended_calendar_click",
        category: "Recommended Calendars",
        label: "Recommended Calendar Click",
        data: {
          calendarHandle: calendar.handle,
          calendarId: calendar._id,
        },
      });
      navigate(`/${calendar.handle}/${calendar._id}`);
    }
  };

  const eventUniqueNumber = useMemo(
    () => getEventUniqueNumber(eventId || "0", position),
    [eventId, position]
  );

  return (
    <Card
      id="recommended-calendar-card"
      data-testid={`recommended-calendar-card-${position}`}
      variant="outlined"
      sx={{ width: "100%", border: "none", overflow: "visible" }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {showRanking && (
          <Typography
            id="recommended-calendar-ranking"
            variant="h1"
            sx={{
              position: "relative",
              zIndex: 0,
              lineHeight: "0 !important",
              paintOrder: "stroke fill",
              opacity: loading ? "0" : "1",
              transition: "all 300ms ease-in-out 100ms",
              WebkitTextStroke: "2px black",
              color: "white",
              width: isMediumScreen ? "76px" : "73px",
              textAlign: "right",
              top: "-3px",
              ...(index + 1 === 1
                ? {
                    left: !isMediumScreen ? "15px" : "16px",
                    fontSize: "11.3rem !important",
                  }
                : {
                    fontSize: "11rem !important",
                  }),
            }}
          >
            {index + 1}
          </Typography>
        )}

        <Box
          id="image-recommended-calendar-box"
          position="relative"
          onClick={handleNavigate}
          sx={{ cursor: "pointer", flex: 1 }}
        >
          {displayImage ? (
            <EventProfileBg
              loading={loading}
              profileImage={displayImage}
              calendarId={calendarId}
              eventSummary={eventSummary}
              height={"140px"}
              width="100%"
              sx={{ position: "relative", zIndex: 1 }}
              handleImageError={handleImageError}
            />
          ) : (
            <DefaultImageBasketball
              height={"140px"}
              data-testid={`image-recommended-calendar-img-${calendarId}`}
              width="100%"
              loading={loading}
              eventUniqueNumber={eventUniqueNumber}
            />
          )}

          {isEventLive && (
            <LiveBadge
              data-testid={`calendar-recommended-calendar-live-${position}`}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="text"
          onClick={handleNavigate}
          sx={{ pl: 0, display: "grid" }}
        >
          <Typography
            variant="subtitle1"
            textTransform="none"
            data-testid="calendar-name"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
            }}
          >
            {calendar?.name || ""}
          </Typography>
        </Button>

        <Typography
          variant="subtitle2"
          color="text.secondary"
          textTransform="none"
          sx={{ textWrap: "nowrap" }}
        >
          {isMediumScreen
            ? formatSubscribers(calendar?.subscribers)
            : formatSubscribers(calendar?.subscribers, false)}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          gap: "19px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          id="calendar-recommended-calendar-description"
          sx={{ ...theme.clampLines(2), minHeight: "2.5em" }}
          dangerouslySetInnerHTML={{
            __html: formattedDescription,
          }}
        />

        {isMediumScreen && (
          <SubscribeButton
            user={user}
            calendar={calendar}
            handle={calendar?.handle}
            onSubscribeAction={onSubscribeAction}
            isEventCard={true}
          />
        )}
      </Box>

      {!isMediumScreen && (
        <CardActions
          id={"calendar-recommended-calendar-add-container"}
          sx={{ p: 0, mt: 2 }}
        >
          <SubscribeButton
            user={user}
            calendar={calendar}
            handle={calendar?.handle}
            onSubscribeAction={onSubscribeAction}
            isEventCard={true}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default memo(EventComposer(RecommendedCalendar));
